<div>
  <h2>{{ 'Settings::Redaction' | abpLocalization }}</h2>
</div>
<br />
<hr />
<form [formGroup]="settingsForm" (ngSubmit)="onSubmitSettings()">
  <div class="form-group">
    <input
      type="checkbox"
      id="dynamicDataMaskingEnabled"
      formControlName="dynamicDataMaskingEnabled"
      (change)="onDynamicDataMaskingEnabledChanged($event)" />
    <label class="ps-1" for="dynamicDataMaskingEnabled">
      {{ 'Settings::RedactionEnabled' | abpLocalization }}
    </label>
  </div>
  <small class="form-text text-muted info-margin">
    {{ 'Settings::RedactionEnabledInfo' | abpLocalization }}
  </small>
  <div *ngIf="settingsForm.get('dynamicDataMaskingEnabled').value">
    <div class="form-group pt-2 selector-class">
      <div class="fw-bold">
        {{ getSelectedCountText() }}
      </div>
      <ng-container *ngIf="loading">
        <i class="far fa-spinner fa-spin"></i>
        <span>{{ 'AbpUi::ProcessingWithThreeDot' | abpLocalization }}</span>
      </ng-container>
      <ng-container *ngIf="!loading">
        <div *ngIf="phraseTypeList?.length">
          <div class="entity-container">
            <ng-container *ngFor="let group of groupByList | keyvalue">
              <div class="w-100 fw-bold">
                {{ group.key }}
              </div>
              <div
                *ngFor="let phraseType of group.value"
                class="entity-column"
                [title]="
                  phraseType.isUsedInRecognition
                    ? ('Settings::EntityUseInRecognition' | abpLocalization)
                    : ''
                ">
                <div class="entity-column-label" [disabled]="phraseType.isUsedInRecognition">
                  <div class="display-inline">
                    <input
                      id="phrase-type-{{ phraseType.id }}"
                      type="checkbox"
                      [checked]="isSelected(phraseType)"
                      [disabled]="phraseType.isUsedInRecognition"
                      (change)="onEntityChange($event, phraseType)" />
                    <label for="phrase-type-{{ phraseType.id }}">
                      {{ phraseType.displayName }}
                      <small class="form-text text-muted ps-1">
                        {{ phraseType.supportedLanguages }}
                      </small>
                    </label>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="form-group pt-2">
      {{ 'Settings::RedactionMode' | abpLocalization }}
      <div *ngFor="let mode of redactionModes">
        <div class="display-inline">
          <input
            id="radio{{ mode.id }}"
            type="radio"
            class="radio-button"
            formControlName="redactionMode"
            [value]="mode.code" />
          <label for="radio{{ mode.id }}" class="ps-1">
            {{ 'GenericLookup::' + mode.code | abpLocalization }}
          </label>
        </div>
        <small class="form-text text-muted info-margin">
          {{ 'Settings::' + mode.name + 'Info' | abpLocalization }}
        </small>
      </div>
    </div>
  </div>
  <div>
    <button type="submit" class="btn btn-primary mt-3 mb-3" [disabled]="disableSaveButton">
      <i class="me-1 fas fa-save"></i>
      {{ 'AbpUi::Save' | abpLocalization }}
    </button>
  </div>
</form>
